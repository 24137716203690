<template>
  <section class="flex min-h-full">
    <SidebarMenu />
    <div class="bg-[#F6F9FC] grow-1 overflow-auto">
      <Header />
      <div class="relative mx-auto w-full overflow-auto">
        <slot class="px-25" />
      </div>

      <Footer />
    </div>
  </section>
  <Toast />
</template>
