<template>
  <div class="container px-11.5 mb-7 flex justify-between items-baseline">
    <div class="color-#6C757D text-3">
      © 2024 Webinterpret. {{ $t("footer.rights") }} |
      <a
        href="https://app.webinterpret.com/terms-and-conditions/mor"
        target="_blank"
        >{{ $t("footer.terms") }}</a
      >
      |
      <a
        href="https://www.webinterpret.com/pdf/privacy-policy-en.pdf"
        target="_blank"
        >{{ $t("footer.privacy") }}</a
      >
    </div>
  </div>
</template>
<script setup lang="ts">
const { availableLocales, locale } = useI18n();
</script>
