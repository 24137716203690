<template>
  <div
    ref="menuElement"
    class="menu-sidebar transition-all z-10 relative"
    :class="{
      'w-61 min-w-61 opened': !menuIsHidden,
      'w-7 min-w-7 closed': menuIsHidden,
    }"
  >
    <div class="sticky top-0">
      <div class="pl-7 h-20 overflow-hidden">
        <img
          src="~/assets/images/webinterpret.svg"
          alt="logo"
          class="block w-48 pt-3"
        />
      </div>
      <nav>
        <ul class="grid p-0 overflow-hidden">
          <li class="font-semibold list-none cursor-pointer">
            <NuxtLink class="outline-none block" to="/">
              <SidebarMenuItem
                class="hover:bg-[#F5F9FF] px-8 py-2.5"
                label="Dashboard"
                icon="pi-chart-bar"
              />
            </NuxtLink>
          </li>
          <li class="font-semibold list-none cursor-pointer">
            <NuxtLink class="outline-none block" to="/orders">
              <SidebarMenuItem
                class="hover:bg-[#F5F9FF] px-8 py-2.5"
                label="Orders"
                icon="pi-shopping-cart"
              />
            </NuxtLink>
          </li>
          <li class="font-semibold list-none cursor-pointer">
            <NuxtLink class="outline-none block" to="/inventory">
              <SidebarMenuItem
                class="hover:bg-[#F5F9FF] px-8 py-2.5"
                label="Inventory"
                icon="pi-globe"
              />
            </NuxtLink>
          </li>

          <li class="font-semibold list-none cursor-pointer">
            <div
              class="w-full flex justify-between items-center px-8 py-2.5 hover:bg-[#F5F9FF] cursor-pointer"
              @click="handleSubMenuToggle('financials')"
            >
              <div class="flex gap-4.5 items-center">
                <i class="pi pi-money-bill text-xl"></i>
                <span class="whitespace-nowrap">Financials</span>
              </div>
              <i
                class="pi"
                :class="{
                  'pi-angle-down': openedMenu.includes('financials'),
                  'pi-angle-right': !openedMenu.includes('financials'),
                }"
              ></i>
            </div>
            <ul v-if="openedMenu.includes('financials')" class="pl-0">
              <li class="pl-12 list-none hover:bg-[#F5F9FF]">
                <NuxtLink class="block py-2.5 font-normal" to="/analytics"
                  >Profit & Loss</NuxtLink
                >
              </li>
              <li class="pl-12 list-none hover:bg-[#F5F9FF]">
                <NuxtLink class="block py-2.5 font-normal" to="/settlements"
                  >Settlements</NuxtLink
                >
              </li>
            </ul>
          </li>

          <li class="font-semibold list-none outline-none cursor-pointer">
            <a
              class="block"
              href="https://support.webinterpret.com/hc/en-us/categories/14960703594130-Marketplace-Brand-Expansion"
              target="_blank"
            >
              <SidebarMenuItem
                class="hover:bg-[#F5F9FF] px-8 py-2.5"
                label="Help Center"
                icon="pi-question-circle"
              />
            </a>
          </li>

          <li
            v-if="isAdmin"
            class="font-semibold list-none outline-none cursor-pointer py-2.5 bg-red-200 cursor-pointer px-8"
          >
            <a
              class="block py-2.5"
              href="https://wi-multichannel-admin.devwebinterpret.com/"
            >
              <div class="flex gap-4.5 items-center text-red">
                <i class="pi text-xl pi-exclamation-triangle text-red"></i>
                <span class="whitespace-nowrap">Admin</span>
              </div>
            </a>
          </li>
        </ul>

        <div
          v-tooltip.right="{
            content: 'Click `]` to toggle menu',
            html: true,
          }"
          class="flex items-center w-7 h-7 absolute top-20 -right-3.5 items-center cursor-pointer border-1 border-solid rounded-full border-white bg-white toggle"
          @click="handleToggleMenu"
        >
          <i
            class="pi text-m m-auto text-black"
            :class="{
              'pi-angle-double-left': !menuIsHidden,
              'pi-angle-double-right': menuIsHidden,
            }"
          ></i>
        </div>
      </nav>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useLocalStorage, onKeyStroke } from "@vueuse/core";

const menuIsHidden = useLocalStorage("wi-menu-is-hidden", false);
const menuElement = ref(null);

const { isAdmin } = useUser();
const openedMenu: Ref<string[]> = ref([]);

const handleToggleMenu = () => {
  menuIsHidden.value = !menuIsHidden.value;
  if (menuElement.value)
    menuElement.value.classList.value.replace(" hovered", "");
};
const handleSubMenuToggle = (item: string) => {
  const index = openedMenu.value.indexOf(item);

  if (index === -1) {
    openedMenu.value.push(item);
  } else {
    openedMenu.value.splice(index, 1);
  }
};

onKeyStroke("]", (e) => {
  e.preventDefault();
  handleToggleMenu();
});
</script>
<style scoped lang="scss">
// Convert to tailwind
.menu-sidebar,
.toggle {
  box-shadow:
    0px 1px 5px rgba(0, 0, 0, 0.12),
    0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.2);
}

@container (min-width: 160px) {
  .card h2 {
    font-size: 2em;
  }
}
// .menu-sidebar:not(.opened):hover:not(:has(.toggle:hover)) {
//   min-width: 15.25rem;
// }
</style>
