<template>
  <header
    class="border-b-solid border-1 border-[#DFE7EF] px-5.75 py-4.5 flex justify-end gap-2 items-center"
  >
    <div
      class="flex justify-end gap-2 items-center pointer cursor-pointer select-none"
      @click="toggle"
    >
      <Avatar :label="user.name?.[0]?.toUpperCase() || 'W'" />
      <div>
        <p class="font-medium">{{ user.name }}</p>
        <p class="text-xs">Profile</p>
      </div>

      <i class="pi pi-angle-down text-[#8d9097]"></i>
    </div>
    <Menu
      id="overlay_menu"
      ref="menu"
      class="min-w-40 text-sm !top-15"
      :model="items"
      :popup="true"
    >
      <template #item="{ item, props }">
        <NuxtLink
          v-if="item.route"
          class="p-menuitem-link outline-none block py-2.5 flex items-center pl-3.5"
          :to="item.route"
        >
          <span :class="item.icon" />
          <span class="ml-3">{{ item.label }}</span>
        </NuxtLink>
        <div
          v-else
          class="p-menuitem-link outline-none block py-2.5 flex items-center pl-3.5"
          @click="item.action"
        >
          <span :class="item.icon" />
          <span class="ml-3">{{ item.label }}</span>
        </div>
      </template>
    </Menu>
  </header>
</template>
<script setup lang="ts">
const { user } = useUser();
const { logOut } = useAuth();
const router = useRouter();

const menu = ref();

const logOutHandler = async () => {
  await logOut();
  router.push("/login");
};

const items = ref([
  {
    label: "Invoices",
    icon: "pi pi-file",
    route: "/invoices",
  },
  {
    label: "Logout",
    icon: "pi pi-power-off",
    action: logOutHandler,
  },
]);

const toggle = (event) => {
  menu.value.toggle(event);
};
</script>
